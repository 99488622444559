import React from "react";
import {
  MdEmail,
  MdPhone,
  MdOutlineAccountCircle,
  MdKeyboardArrowDown,
} from "react-icons/md";

import routes from "../../../../clhbid-routes";

import { useAuth } from "../../../concerns/auth";
import { isHoverSupported } from "../../../helpers/media-queries";

import Button from "../../Button";

const UserMenu: React.FC<{
  menuOpen: boolean;
  setMenuOpen: (arg1: boolean) => void;
}> = ({ menuOpen, setMenuOpen }) => {
  const { editAccount, state, logout, user } = useAuth();

  if (state !== "authenticated") return null;

  return (
    <div
      className="lg:border-l lg:border-gray-300 lg:py-[6px] lg:pl-8"
      onMouseEnter={() => {
        if (isHoverSupported()) setMenuOpen(true);
      }}
      onMouseLeave={() => {
        if (isHoverSupported()) setMenuOpen(false);
      }}
      data-testid="user-menu"
    >
      <button
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
        className="flex h-full items-center leading-none"
        data-testid="user-menu-toggle"
      >
        <MdOutlineAccountCircle className="h-10 w-10 p-[5px] lg:h-8 lg:w-8" />
        <span
          className={[
            "hidden lg:inline lg:font-semibold",
            menuOpen ? "underline" : "no-underline",
          ].join(" ")}
        >
          {user?.UserName}
          <MdKeyboardArrowDown className="ml-[2px] inline h-4 w-[14px] pb-[1px]" />
        </span>
      </button>
      <div
        className={[
          "absolute bottom-[calc(-100vh+100%)] left-0 right-0 top-[100%] z-[-2] overflow-y-auto duration-300 [transition-property:visibility,transform] lg:bottom-auto lg:left-auto lg:right-0 lg:top-[calc(100%-32px)] lg:z-0 lg:overflow-y-visible lg:pr-4 lg:pt-10 lg:transition-opacity xl:right-[calc((100%-1200px)/2)]",
          menuOpen
            ? "visible translate-x-0 lg:translate-x-0 lg:opacity-100"
            : "invisible translate-x-full lg:translate-x-0 lg:opacity-0",
        ].join(" ")}
        data-testid="user-menu-wrapper"
      >
        <div className="h-full bg-clhbid-gray p-4 lg:rounded-md lg:shadow-[0_0_8px_0_rgba(0,0,0,0.4)]">
          <div className="mb-4 flex-row">
            <h3 className="mb-2 font-bold">User Menu</h3>
            <div className="flex space-x-2">
              <MdEmail className="h-5 w-5 text-white" />{" "}
              <span className="ml-1" data-testid="">
                {user?.Email}
              </span>
            </div>
            <div className="flex space-x-2">
              <MdPhone className="h-5 w-5 text-white" />{" "}
              <span className="ml-1">
                {user?.PhoneNumber ? (
                  user.PhoneNumber
                ) : (
                  <button
                    onClick={editAccount}
                    className="text-clhbid-orange hover:underline"
                  >
                    Add your number
                  </button>
                )}
              </span>
            </div>
          </div>
          {user?.Admin && (
            <div className="mb-4 flex-row">
              <h3 className="mb-2 font-bold">Admin Menu</h3>
              <a
                href={routes.admin_sales()}
                className="block text-clhbid-orange hover:underline"
              >
                Admin Sales
              </a>
              <a
                href={routes.admin_users()}
                className="block text-clhbid-orange hover:underline"
              >
                Admin Users
              </a>
            </div>
          )}
          <Button
            size="small"
            onClick={editAccount}
            external
            outline
            family="primary"
            className="mt-2 block w-full text-center leading-tight"
          >
            Edit Your Account
          </Button>
          <Button
            size="small"
            onClick={logout}
            className="mt-2 block w-full text-center leading-tight"
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
