import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import axios from "axios";
import {
  MdAlternateEmail,
  MdPhone,
  MdQuestionAnswer,
  MdPerson,
} from "react-icons/md";

import { isEmailAddress, isPhoneNumber } from "../helpers/validators";

import Input from "./Input";
import Button from "./Button";
import config from "../../clhbid-config";

type Inputs = {
  "bot-field": string;
  "form-name": string;
  "first-name": string;
  "last-name": string;
  "phone-number": string;
  "email-address": string;
  message: string;
};

const MiniContactForm: React.FC<{ className?: string }> = ({ className }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);

    try {
      await Promise.all([
        axios("/", {
          method: "POST",
          data: new URLSearchParams(data),
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }),
        axios({
          method: "post",
          url: `${config.AUCTION_ENGINE_URL}/api/account/subscribe-newsletter`,
          data: {
            FirstName: data["first-name"],
            LastName: data["last-name"],
            Email: data["email-address"],
            PhoneNumber: `+1${data["phone-number"].replace(/\D/g, "")}`,
            Source: "MiniContactForm",
          },
        }),
      ]);

      window?.gtag?.("event", "contactform_submit", {
        event_label: "Mini contact form submitted",
      });
      window?.posthog?.capture("ContactForm.submitted", data);

      toast(
        `Thanks for contacting us! We will get in touch with you shortly.`,
        {
          type: "success",
        }
      );

      reset();
    } catch (err) {
      Sentry.captureException(err);
      console.error("could not submit", err);
      toast(`An error occurred while submitting. Please try again.`, {
        type: "error",
      });
    }

    setLoading(false);
  };

  return (
    <form
      name="contact"
      data-netlify="true"
      // eslint-disable-next-line react/no-unknown-property
      netlify-honeypot="bot-field"
      onSubmit={handleSubmit(onSubmit)}
      className={className}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" {...register("form-name", { value: "contact" })} />
      <div className="">
        <div className="flex gap-4">
          <Input
            type="text"
            label={
              <>
                <MdPerson className="mr-1" />
                First Name
              </>
            }
            placeholder=" "
            errors={errors}
            {...register("first-name", {
              required: "First name is required",
            })}
            className="relative mb-4 w-full [&:has(input:not(:placeholder-shown))_label]:hidden"
            inputClassName="w-full rounded-md bg-clhbid-gray border-0 focus:ring-0 focus:ring-offset-0 focus:shadow-button-focus"
            labelClassName="absolute flex left-[12px] top-[8px] pointer-events-none items-center text-clhbid-silver"
            errorClassName="absolute left-0 right-0 -bottom-4 text-xs text-red-500 mb-0 italic"
          />
          <Input
            type="text"
            label={
              <>
                <MdPerson className="mr-1" />
                Last Name
              </>
            }
            placeholder=" "
            errors={errors}
            {...register("last-name", {
              required: "Last name is required",
            })}
            className="relative mb-4 w-full [&:has(input:not(:placeholder-shown))_label]:hidden"
            inputClassName="w-full rounded-md bg-clhbid-gray border-0 focus:ring-0 focus:ring-offset-0 focus:shadow-button-focus"
            labelClassName="absolute flex left-[12px] top-[8px] pointer-events-none items-center text-clhbid-silver"
            errorClassName="absolute left-0 right-0 -bottom-4 text-xs text-red-500 mb-0 italic"
          />
        </div>
        <Input
          type="email"
          label={
            <>
              <MdAlternateEmail className="mr-1" />
              Email Address
            </>
          }
          placeholder=" "
          errors={errors}
          {...register("email-address", {
            required: "Email address is required",
            validate: {
              isEmailAddress,
            },
          })}
          className="relative mb-4 [&:has(input:not(:placeholder-shown))_label]:hidden"
          inputClassName="w-full rounded-md bg-clhbid-gray border-0 focus:ring-0 focus:ring-offset-0 focus:shadow-button-focus"
          labelClassName="absolute flex left-[12px] top-[8px] pointer-events-none items-center text-clhbid-silver"
          errorClassName="absolute left-0 right-0 -bottom-4 text-xs text-red-500 mb-0 italic"
        />
        <Input
          type="tel"
          label={
            <>
              <MdPhone className="mr-1" />
              Phone Number
            </>
          }
          placeholder=" "
          errors={errors}
          {...register("phone-number", {
            required: "Phone number is required",
            validate: {
              isPhoneNumber,
            },
          })}
          className="relative mb-4 [&:has(input:not(:placeholder-shown))_label]:hidden"
          inputClassName="w-full rounded-md bg-clhbid-gray border-0 focus:ring-0 focus:ring-offset-0 focus:shadow-button-focus"
          labelClassName="absolute flex left-[12px] top-[8px] pointer-events-none items-center text-clhbid-silver"
          errorClassName="absolute left-0 right-0 -bottom-4 text-xs text-red-500 mb-0 italic"
        />
        <Input
          type="textarea"
          label={
            <>
              <MdQuestionAnswer className="mr-1" />
              We love to talk farmland!
            </>
          }
          placeholder=" "
          errors={errors}
          rows={2}
          {...register("message", {
            required: "Message is required",
          })}
          className="relative mb-4 [&:has(textarea:not(:placeholder-shown))_label]:hidden"
          inputClassName="block w-full rounded-md bg-clhbid-gray border-0 focus:ring-0 focus:ring-offset-0 focus:shadow-button-focus"
          labelClassName="absolute flex left-[12px] top-[8px] pointer-events-none items-center text-clhbid-silver"
          errorClassName="absolute left-0 right-0 -bottom-4 text-xs text-red-500 mb-0 italic"
        />
        <p className="mb-4 text-clhbid-silver">
          You&apos;ll be subscribed to our weekly update and be the first to
          know about upcoming sales!
        </p>
      </div>
      <Button loading={loading} outline className="block w-full" type="submit">
        Contact Us
      </Button>
    </form>
  );
};

export default MiniContactForm;
